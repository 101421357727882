<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6" lg="8">
        <b-card :header="$t('message.createPromotion')">
          <table class="table-l">
            <tr>
              <td>{{ $t('message.type') }}</td>
              <td>
                <b-form-select
                  :options="machineType"
                  v-model="selectType.type"
                  text-field="name"
                  value-field="id"
                  @input="getMachinePromotion()"
                ></b-form-select>
              </td>
            </tr>
            <template v-if="selectType.type">
              <tr>
                <td>{{ $t('message.selectPromotion') }}</td>
                <td>
                  <b-form-select
                    :options="promotionType"
                    text-field="name"
                    value-field="value"
                    v-model="promotionData"
                  ></b-form-select>
                </td>
              </tr>
              <tr>
                <td>{{ $t('message.promotionName') }}</td>
                <td>
                  <b-form-input v-model="promotionData.name"></b-form-input>
                </td>
              </tr>
              <tr v-if="selectType.type === 3">
                <td>{{ $t('message.machineIdFarm') }}</td>
                <td>
                  <model-list-select
                    :list="machineData"
                    v-model="promotionData.machineId"
                    option-value="machineId"
                    option-text="name"
                    placeholder="Select Machine ID"
                  ></model-list-select>
                </td>
              </tr>
              <tr v-else>
                <td>{{ $t('message.machineId') }}</td>
                <td>
                  <b-form-input v-model="promotionData.machineId"></b-form-input>
                </td>
              </tr>
              <template v-if="promotionData.promotionCode === 'XFREEX'">
                <!-- <tr>
                  <td>ประเภทเครื่อง</td>
                  <td>
                    <b-form-select
                      :options="machineType"
                      v-model="promotionData.config.type"
                      text-field="name"
                      value-field="name"
                    ></b-form-select>
                  </td>
                </tr>-->
                <tr>
                  <td>{{ $t('message.transactionType') }}</td>
                  <td>
                    <model-list-select
                      :list="type"
                      v-model="promotionData.config.type"
                      option-value="type"
                      option-text="name"
                    ></model-list-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.count') }}</td>
                  <td>
                    <b-form-input type="number" min="1" v-model="promotionData.config.count"></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.reward') }}</td>
                  <td>
                    <list-select
                      :list="coupons.coupons"
                      option-value="couponId"
                      option-text="name"
                      :selected-item="selectedCoupon"
                      @select="selectCoupon"
                    ></list-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.valueIfFree') }}</td>
                  <td>
                    <b-form-input type="number" min="0" v-model="promotionData.config.value"></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.availiable') }} ({{ $t('message.day') }})</td>
                  <td>
                    <b-form-input
                      type="number"
                      min="1"
                      max="30"
                      v-model="promotionData.config.expireDate"
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.type') }}</td>
                  <td>
                    <model-list-select
                      :list="stepType"
                      v-model="promotionData.config.mode"
                      option-value="type"
                      option-text="name"
                    ></model-list-select>
                  </td>
                </tr>
              </template>
              <template v-else-if="promotionData.promotionCode === 'STEPPRICES'">
                <tr>
                  <td>{{ $t('message.transactionType') }}</td>
                  <td>
                    <model-list-select
                      :list="type"
                      v-model="promotionData.config.type"
                      option-value="type"
                      option-text="name"
                    ></model-list-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.type') }}</td>
                  <td>
                    <model-list-select
                      :list="stepType"
                      v-model="promotionData.config.mode"
                      option-value="type"
                      option-text="name"
                    ></model-list-select>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('message.setting') }}</td>
                  <td>
                    <b-btn
                      variant="primary"
                      @click="showSettingModal(promotionData.config.details)"
                    >{{ $t('message.setting') }}</b-btn>
                  </td>
                </tr>
              </template>
              <tr>
                <td>{{ $t('message.period') }}</td>
                <td>
                  <date-picker
                    range
                    type="datetime"
                    v-model="promotionData.time"
                    lang="en"
                    format="yyyy-MM-dd"
                  ></date-picker>
                </td>
              </tr>
            </template>
          </table>
          <br />
          <div class="float-right">
            <b-btn variant="danger" v-on:click="goback">{{ $t('message.back') }}</b-btn>&nbsp;
            <b-btn variant="success" v-on:click="createPromotion">{{ $t('message.create') }}</b-btn>
          </div>
        </b-card>
      </b-col>

      <!-- <b-col sm="6" lg="8">
                <b-card header="ภาพตัวอย่าง">

                </b-card>
      </b-col>-->
    </b-row>
    <b-modal ref="settingModal" hide-header size="lg">
      <table class="table2">
        <template v-for="(items, index, key) in settingModalData">
          <td>
            <tr>
              <strong>{{ $t('message.step') }} {{index+1}}</strong>
            </tr>
            <tr>
              {{ $t('message.startCount') }} :
              <b-form-input type="number" min="0" v-model="items.start"></b-form-input>
            </tr>
            <tr>
              {{ $t('message.endCount') }} :
              <b-form-input type="number" min="0" v-model="items.end"></b-form-input>
            </tr>
            <tr>
              {{ $t('message.discountPrice') }} :
              <b-form-input type="number" min="0" v-model="items.value"></b-form-input>
            </tr>
          </td>
        </template>
      </table>
      <div slot="modal-footer" class="w-100">
        <b-btn
          variant="success"
          class="float-left"
          v-on:click="addPackage(settingModalData)"
        >{{ $t('message.addPacket') }}</b-btn>&nbsp;
        <b-btn
          variant="success"
          v-on:click="deletePackage(settingModalData)"
        >{{ $t('message.removePacket') }}</b-btn>
        <b-btn
          variant="primary"
          class="float-right"
          @click="closePricesModal()"
        >{{ $t('message.save') }}</b-btn>
      </div>
    </b-modal>
  </div>
</template>


<script>
import webServices from "../../script";
import DatePicker from "../../custom_modules/vue2-datepicker";
import {
  ListSelect,
  ModelListSelect
} from "../../custom_modules/search-select";
import moment from "moment";
export default {
  name: "createPromotion",
  components: {
    DatePicker,
    ListSelect,
    ModelListSelect
  },
  data() {
    return {
      machineType: [],
      promotionType: [],
      selectType: {},
      promotionData: {
        config: {}
      },
      selectedCoupon: {
        name: "",
        couponId: ""
      },
      selectedId: {},
      machineData: {},
      coupons: [],
      coupon: [{ value: 1, text: "test" }],
      settingModalData: {},
      type: [
        { name: "ซักผ้า", type: "washer" },
        { name: "อบผ้า", type: "dryer" },
        { name: "ซักและอบ", type: "wnd" },
        { name: "เครื่องขายน้ำยา", type: "vending" }
      ],
      stepType: [
        { name: "ช่วงเวลา", type: "period" },
        { name: "ตัดรายเดือน", type: "monthly" }
      ]
    };
  },
  methods: {
    getMachineType() {
      webServices.getMachineType().then(res => {
        this.machineType = res.data;
      });
    },
    getMachine() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then(res => {
          this.$Progress.finish();
          this.machineData = res.data;
        })
        .catch(err => {
          this.$Progress.fail();
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    async getMachinePromotion() {
      webServices
        .getPromotionType(this.selectType)
        .then(res => {
          this.promotionType = res.data;
        })
        .catch(err => {
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
        });
      webServices.getCoupons().then(res => {
        this.coupons = res.data;
      });
    },
    selectCoupon(item) {
      this.selectedCoupon = item;
      this.promotionData.config.code = item.code;
      this.promotionData.config.couponId = item.couponId;
    },
    createPromotion() {
      var timeEnd = moment(this.promotionData.time[1]).format("HH:mm:ss");
      if (timeEnd === "00:00:00" || timeEnd === "12:00:00 AM") {
        this.promotionData.time[1].setHours(23, 59, 59, 999);
      }
      webServices
        .createPromotion(this.promotionData)
        .then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ"
            });
            this.$router.push("/promotion/all");
          } else {
            const errorText = {
              // errorCode: res.data.errorCode,
              errorText: res.data.errorText
            };
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(errorText)
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    showSettingModal(data) {
      this.settingModalData = data;
      this.$refs.settingModal.show();
    },
    addPackage(data) {
      let lastItem = Object.assign({}, data[data.length - 1]);
      data.push(lastItem);
    },
    deletePackage(data) {
      data.pop();
    },
    closePricesModal() {
      this.$refs.settingModal.hide();
    },
    goback() {
      this.$router.push("/promotion/all");
    }
  },
  mounted() {
    this.getMachineType();
    this.getMachine();
  }
};
</script>